import { authStore } from '@stores/auth'
import head from 'lodash/head'
function checkAuthentication(to, from, next) {
  let authData = authStore()
  const user = authData.user
  if (user) {
    const account = head(user.accounts) || {}
    if (account.isActive) {
      return next('/search')
    }
    return next('/onboarding/trial')
  }
  return next()
}

export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('@auth/Auth0Login.vue').then((c) => c.default || c),
    meta: {
      title: 'Login',
      event: () => 'Login',
      metaInfo: {
        title: 'Login'
      },
      hideFooter: true,
      hideHeader: true
    },
    beforeEnter: async (to, from, next) => {
      return checkAuthentication(to, from, next)
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@auth/index.vue').then((c) => c.default || c),
    meta: {
      title: 'Register',
      event: () => 'Register',
      metaInfo: {
        title: 'Register'
      },
      hideFooter: true,
      hideHeader: true
    },
    beforeEnter: async (to, from, next) => {
      return checkAuthentication(to, from, next)
    }
  },
  {
    // Forgot password - if a user forgets their password, they go to this page which allows them to prove it's them and log in
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('@auth/ForgotPassword.vue').then((c) => c.default || c),
    meta: {
      title: 'Forgot Password',
      event: () => 'Forgot Password',
      metaInfo: {
        title: 'Forgot Password'
      }
    }
  },
  {
    // Verify email - Page after /register where it tells user an email has been sent to verify their email
    path: '/verify-email',
    name: 'verify-email',
    component: () => import('@auth/VerifyEmail.vue').then((c) => c.default || c),
    meta: {
      title: 'Verify Email',
      event: () => 'Verify Email',
      metaInfo: {
        title: 'Verify your Email'
      },
      hideHeader: true,
      requiresAuth: true
    }
  },
  {
    path: '/external-link',
    name: 'external-link',
    component: () => import('@auth/ExternalLink.vue').then((c) => c.default || c),
    meta: {
      title: 'External Link',
      event: () => 'External Link'
    }
  },
  {
    // Reset password - when user receives a password reset email, this page is the link sent to them in the email that allows them to change their password.
    path: '/reset-password/:token',
    name: 'reset-password',
    component: () => import('@auth/ResetPassword.vue').then((c) => c.default || c),
    meta: {
      title: 'Reset Password',
      event: () => 'Reset Password',
      metaInfo: {
        title: 'Password Reset'
      }
    }
  }
]
