<template>
  <v-card flat class="pa-0">
    <v-card-title v-if="options.loginTitle">{{ options.loginTitle }}</v-card-title>
    <v-card-subtitle v-if="$route?.query?.email && $route?.query?.accountName" class="mb-2">
      Log in with <strong>{{ $route?.query?.email }}</strong> to access
      <strong>{{ $route?.query?.accountName }}</strong> on SuperScout
    </v-card-subtitle>
    <vee-form :validation-schema="schema" @submit="loginCall">
      <v-card-text class="pb-0 pa-2">
        <div class="pb-3">
          <TextField name="email" type="email" placeholder="Email" title="Email address" />
        </div>
        <PasswordField name="password" hint=" " title="Password" placeholder="Password" />
      </v-card-text>
      <v-card-actions class="py-0">
        <v-row>
          <!-- <v-col cols="6" sm="6">
            <v-btn variant="tonal" class="text-none font-bold" block @click="cancel()"
              >Cancel</v-btn
            >
          </v-col> -->
          <v-col cols="12" sm="12">
            <!-- <button
              type="submit"
              :disabled="$loading.login"
              class="flex w-full justify-center rounded-md bg-primary px-3 py-1.5 text-sm font-semibold leading-6 text-lightGrey shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              <AnimateSpin v-if="$loading.login" />
              <span v-else> Login </span>
            </button> -->
            <!-- <button
              @click="login2"
              class="flex w-full justify-center rounded-md bg-primary px-3 py-1.5 text-sm font-semibold leading-6 text-lightGrey shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              <AnimateSpin v-if="$loading.login" />
              <span> Login 2 </span>
            </button> -->
            <!-- <v-btn
              variant="flat"
              color="primary"
              class="text-none font-bold"
              block
              :loading="$loading.login"
              @click="$validateForm({ email, password }, login)"
              >Login</v-btn
            > -->
          </v-col>
        </v-row>
      </v-card-actions>
    </vee-form>
    <v-card-text>
      <div class="text-center">
        <v-btn
          text
          variant="plain"
          class="text-none text-decoration-underline"
          @click="go('forgot-password')"
          >Forgot password?</v-btn
        >
      </div>
      <div v-if="details" text variant="plain" class="text-none">
        Don't have an account?
        <v-btn text @click="go('register')">Sign up now</v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import * as yup from 'yup'
import { AuthMixin } from '@mixins'
import TextField from '@form-fields/TextField.vue'
import PasswordField from '@form-fields/PasswordField.vue'
import AnimateSpin from '@components/icons/AnimateSpin.vue'
export default {
  name: 'LoginBase',
  props: {
    details: { type: Boolean, default: false },
    options: { type: Object, default: () => ({}) }
  },
  components: {
    TextField,
    PasswordField,
    AnimateSpin
  },
  mounted() {
    this.$auth0.logout()
    this.$auth0.loginWithRedirect({
      appState: { target: '/external-link?target=auth0-callback' }
    })
    // if (this.$user && this.$accountId) {
    //   this.$next({ name: 'search.view' })
    // }
  },
  mixins: [AuthMixin],
  data() {
    return {
      schema: yup.object({
        email: yup
          .string()
          .required('Email is required')
          .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Email is not valid'),
        password: yup.string().required('Password is required')
      })
    }
  },
  methods: {
    loginCall(values) {
      this.email = values.email
      this.password = values.password
      this.login()
    },
    go(name) {
      this.$loginDialog(false)
      this.$next({ name })
    },
    cancel() {
      this.emitter.emit('auth-dialog', false)
    },
    async login2() {
      await this.$auth0.loginWithRedirect()
    },
    logout2() {
      this.$auth0.logout()
    },
    async getToken() {
      const token = await this.$auth0.getAccessTokenSilently()
    }
  }
}
</script>
