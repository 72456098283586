<template>
  <vee-form :validation-schema="schema" @submit="submit" :initial-values="$route?.query">
    <v-card flat class="pa-0">
      <v-card-title v-if="options.loginTitle">{{ options.loginTitle }}</v-card-title>
      <v-card-subtitle
        v-if="$route?.query?.email && $route?.query?.accountName && $route?.query?.tab != 0"
        class="mb-2"
      >
        Log in with <strong>{{ $route?.query?.email }}</strong> to access
        <strong>{{ $route?.query?.accountName }}</strong> on SuperScout
      </v-card-subtitle>
      <v-card-text class="pb-0">
        <TextField
          density="compact"
          name="email"
          type="email"
          title="Email"
          variant="outlined"
          autocomplete="email"
          color="primary"
          placeholder="Email"
          class="mb-3"
        />
        <PhoneField name="phone" title="Phone" @setValue="setPhoneVal($event)" />

        <PasswordField name="password" hint=" " title="Password" placeholder="Password" />
        <PasswordField
          name="password_confirmation"
          hint=" "
          title="Confirmation Password"
          placeholder="Confirmation Password"
        />

        <v-row dense align="center" justify="center" class="mb-6">
          <v-col cols="12" sm="6" class="pb-0">
            <TextField name="firstName" type="text" title="First name" />
          </v-col>
          <v-col cols="12" sm="6" class="pb-0">
            <TextField name="lastName" type="text" title="Last name" />
          </v-col>
        </v-row>

        <TermPolicyField name="policy" />
      </v-card-text>
      <v-card-actions class="py-0">
        <v-row justify="center">
          <!-- <v-col v-if="$route.name != 'login'" cols="6" sm="6">
            <v-btn variant="tonal" class="text-none font-bold" block @click="cancel()"
              >Cancel</v-btn
            >
          </v-col> -->

          <v-col cols="6" sm="6">
            <!-- :disabled="!user.policy || loading" -->
            <button
              type="submit"
              :disabled="loading"
              class="flex w-full justify-center rounded-md bg-primary px-3 py-1.5 text-sm font-semibold leading-6 text-lightGrey shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              <AnimateSpin v-if="loading" />
              <span v-else> Create my account </span>
            </button>
            <!-- <v-btn
              variant="flat"
              class="text-none font-bold"
              block
              :color="user.policy ? 'primary' : 'dark'"
              :disabled="!user.policy"
              :loading="loading"
              @click="$validateForm(user, submit)"
              >Create my account</v-btn
            > -->
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </vee-form>
</template>
<script>
import * as yup from 'yup'
import { UserService } from '@services'
import { User } from '@models/user.model'
import { AuthMixin } from '@mixins'
import PasswordField from '@form-fields/PasswordField.vue'
import TextField from '@form-fields/TextField.vue'
import TermPolicyField from '@form-fields/TermPolicyField.vue'
import PhoneField from '@form-fields/PhoneField.vue'
import AnimateSpin from '@components/icons/AnimateSpin.vue'

export default {
  name: 'RegisterBase',
  components: {
    PasswordField,
    TextField,
    TermPolicyField,
    PhoneField,
    AnimateSpin
  },
  props: {
    details: { type: Boolean, default: false },
    options: { type: Object, default: () => ({}) }
  },

  mixins: [AuthMixin],

  data() {
    return {
      loading: false,
      schema: yup.object({
        email: yup
          .string()
          .required('Email is required')
          .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Email is not valid')
          .test('unique-email', 'Email already exists', async (value) => {
            if (!value || !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value))
              return true
            const response = await UserService.uniqueEmail({ email: value })
            return !(response && response.data)
          }),
        password: yup
          .string()
          .required('Password is required')
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/,
            'Password must contain minimum eight characters, at least one uppercase letter, one lowercase letter and one number'
          ),
        phone: yup.string().optional(),
        password_confirmation: yup
          .string()
          .required('Please retype your password.')
          .oneOf([yup.ref('password'), null], 'The password confirmation does not match.'),
        firstName: yup.string().required('First name is required'),
        lastName: yup.string().required('Last name is required'),
        policy: yup.boolean().required('You must accept the terms and conditions')
      })
    }
  },
  methods: {
    cancel() {
      this.emitter.emit('auth-dialog', false)
    },
    async submit(values) {
      if (this.loading) {
        return false
      }
      this.user = new User({
        ...values,
        phone: this.user.phone || null,
        countryCode: this.user.countryCode || null
      })
      this.loading = true

      await this.register()

      this.loading = false
      this.$loginDialog(false)
    },
    setPhoneVal(val = {}) {
      if (this.user?.phone != val.phone) {
        this.user.phone = val.phone
        this.user.countryCode = val.countryCode
      }
    }
  }
}
</script>
