import { AuthService, UserService, LocationService, StorageService } from '@services'
import { User } from '@models/user.model'
import { authStore } from '@stores/auth'
import get from 'lodash/get'
import { getActivePinia } from 'pinia'

export default {
  name: 'AuthMixin',

  data() {
    return {
      valid: true,
      user: new User(),
      source: 'custom',
      email: '',
      password: ''
    }
  },

  mounted() {
    this.emitter.on('logout', this.logout)
    this.email = this.$route?.query?.email || this.$route?.query?.username
    this.password = this.$route?.query?.password

    if (this.email && this.password) {
      this.login()
    }
  },
  beforeDestroy() {
    this.emitter.off('logout', this.logout)
  },
  methods: {
    cancel() {
      this.$refs?.form?.reset()
    },

    async login(response) {
      this.$loading.login = true
      // const data = {
      //   token
      // }

      // if (source) {
      //   data.source = source
      // } else {
      //   data.login = user.login ? user.login : this.email
      //   data.password = user.password ? user.password : this.password
      //   data.source = 'custom'
      // }

      // const response = await AuthService.login(data)

      if (response) {
        this.$setUserToken(response.data)
        let authData = authStore()
        await authData.getUser()
        await this.$setAllPermission()

        if (this.$user) {
          const lastSessionAccountId = get(this.$user, 'activities.sessionInfo.lastAccountId')
          if (lastSessionAccountId) {
            const selectedAccount = this.$user?.accounts?.find(
              (_account) => _account._id?.toString() === lastSessionAccountId?.toString()
            )
            this.setAccountId(selectedAccount?.slug || null)
          } else {
            this.setAccountId(this.$user?.accounts[0]?.slug || null)
          }
          if (!this.$user.isVerified) {
            this.$next({ name: 'verify-email' })
          } else if (!this.$accountId) {
            this.$next({ name: 'onboarding-trial' })
          } else if (!this.$isSubscribed) {
            this.$next({ name: 'onboarding-pricing' })
          } else if (this.$route.query.redirect) {
            this.$router.push(this.$route.query.redirect)
          } else {
            const locations = await this.getLocationsCount()
            if (locations > 1) {
              this.$next({ path: '/' })
            } else {
              this.$next({ name: 'location.list' })
            }
          }
        } else {
          this.$router.push({ name: 'login' })
        }

        this.$loading.login = false
        this.$loginDialog(false)
        return response
      }

      this.$loading.login = false
      this.$loginDialog(false)
      return response
    },

    $setUserToken(response) {
      let token = null
      if (response && response.token) {
        token = response.token
      }
      localStorage.setItem(import.meta.env.VITE_APP_AUTH_TOKEN_KEY, token)
      this.$cookies.set(import.meta.env.VITE_APP_AUTH_TOKEN_KEY, token)
    },

    async loginAfterRegister(user, token = null) {
      let response = null
      let source = null
      //let token = null
      if (user.source !== 'custom') {
        response = await UserService.getById({
          token: this.user.getStrategyId(user)
        })

        if (response && response.data) {
          source = user.source
          token = this.user?.getStrategyId(user)
        }
      }
      const data = {}

      if (source) {
        data.source = source
        data.token = token
      } else {
        data.login = user.email
        data.password = user.password
        data.source = 'custom'
      }

      const res = await this.login('', token, data)

      return res
    },
    async successCallback(response, options = {}) {
      if (response) {
        const { redirect = true, setAccount = true } = options

        const { token, sessionId, user } = response.data

        this.setSessionId(sessionId)
        const userData = user

        if (userData) sessionStorage.setItem('user', JSON.stringify(userData))

        if (redirect) {
          if (!user.isVerified) {
            this.$next({ name: 'verify-email' })
          }
        }
      }
    },

    async register(auth0User, token) {
      // await this.user.makeUsername()

      const user = this.user?.getAuth0RegisterInfo(auth0User)
      const response = await AuthService.register(user)
      if (response && token) {
        this.login({ data: { token } })
        // const token = response.data.token
        // await this.loginAfterRegister(user, token)
      }
    },
    async manualLogout() {
      const token = localStorage.getItem(import.meta.env.VITE_APP_AUTH_TOKEN_KEY)
      if (token && token !== 'null') {
        await AuthService.logout()
      }
      this.logout()
    },
    async logout(options = {}) {

      if (this.$user) {
        getActivePinia()._s.forEach((store) => store.$reset())
        localStorage.removeItem(import.meta.env.VITE_APP_AUTH_TOKEN_KEY)
        localStorage.removeItem('totalLocations')
        this.$cookies.set(import.meta.env.VITE_APP_AUTH_TOKEN_KEY, null)
        window.localStorage.clear()
        window.sessionStorage.clear()
      }
      this.$auth0.logout({
        logoutParams: { returnTo: `${import.meta.env.VITE_APP_BASE_URL}/login` }
      });
      // this.$next({ name: 'login' })
    },

    async getLocationsCount() {
      const filter = {
        status: 'open',
        page: 1,
        limit: 1
      }
      let totalItems = 0
      const response = await LocationService.getByAccountId(filter)
      if (response && response.data?.data) {
        totalItems = response.data?.count || 0
        StorageService.setItem('totalLocations', totalItems)
      }
      return totalItems
    }
  }
}
