import NavigationLayout from '@layouts/NavigationLayout.vue'
import CollectionNavigationLayout from '@layouts/CollectionNavigationLayout.vue'
import routeHelper from './helper'

export default [
  {
    path: '/:accountId',
    redirect: '/',
    name: 'profile',
    component: () => import('@layouts/index.vue').then((c) => c.default || c),
    meta: {
      showBreadcrumb: false
    },
    children: [
      {
        name: 'host.layout',
        path: 'a',
        component: NavigationLayout,
        beforeEnter: routeHelper.beforeEnter,
        meta: {
          showBreadcrumb: false
        },

        children: [
          {
            path: 'overview',
            name: 'overview.view',
            component: () => import('@views/OverviewView.vue').then((c) => c.default || c),
            meta: {
              requiresAuth: true
            }
          },
          {
            path: 'contacts',
            name: 'contacts.view',
            component: () => import('@views/contacts/index.vue').then((c) => c.default || c),
            meta: {
              requiresAuth: true,
              title: 'Contacts',
              event: () => 'Contacts'
            }
          },
          {
            path: 'favourites',
            name: 'favourites.view',
            component: () => import('@views/FavouritesView.vue').then((c) => c.default || c),
            meta: {
              requiresAuth: true
            }
          },
          {
            path: 'workspace',
            name: 'profile.view',
            component: () => import('@views/ProfileView.vue').then((c) => c.default || c),
            meta: {
              requiresAuth: true,
              title: 'Workspace Settings',
              event: () => 'Workspace Settings'
            }
          },
          {
            path: 'shared-links',
            name: 'shared.links',
            component: () => import('@views/SharedLinks.vue').then((c) => c.default || c),
            meta: {
              requiresAuth: true,
              title: 'Links',
              event: () => 'Links'
            }
          },
          {
            path: 'shared-links/:id',
            name: 'shared.links-list',
            component: () => import('@views/SharedLinks.vue').then((c) => c.default || c),
            meta: {
              requiresAuth: true,
              title: 'Links',
              event: () => 'Links'
            }
          },

          {
            path: 'usage',
            name: 'usage.view',
            component: () => import('@views/UsageView.vue').then((c) => c.default || c),
            meta: {
              requiresAuth: true,
              title: 'Usage',
              subtitle: 'All Usage Details',
              event: () => 'Usage'
            }
          },
          {
            path: 'billing',
            name: 'billing.view',
            component: () => import('@views/BillingView.vue').then((c) => c.default || c),
            meta: {
              requiresAuth: true,
              title: 'Billing',
              subtitle: 'All Billing Details',
              event: () => 'Billing'
            }
          },
          {
            path: 'location/list',
            name: 'location.list',
            component: () => import('@views/location/list/index.vue').then((c) => c.default || c),
            meta: {
              requiresAuth: true,
              aboutListings: true,
              hideNavIcon: true,
              title: 'Locations',
              event: () => 'Locations',
              subtitle: 'All locations that you’ve listed',
              noRecordText: 'You have never created a location on SuperScout.', // FIXME: noRecordText should change depending on value of the filter: https:// app.asana.com/0/1176167184780284/1187104732406753/f
              metaInfo: {
                title: 'My Locations',
                description:
                  'View all the locations you have listed on SuperScout, regardless of whether they are open, closed, or drafted.'
              }
            }
          },
          {
            path: 'permissions',
            name: 'permissions.view',
            component: () => import('@views/permissions/index.vue').then((c) => c.default || c),
            meta: {
              requiresAuth: true,
              title: 'Permissions',
              subtitle:
                'Invite colleagues and collaborators with the "invite user" button below. Everyone you invite will be able to sign into this workspace but will have their own email and password. ',
              event: () => 'Permissions'
            }
          }
        ]
      },
      {
        path: 'location/create',
        name: 'location.create',
        component: () => import('@views/location/create/index.vue'),
        meta: {
          requiresAuth: true,
          hideHeader: true,
          redirect: 'location.list',
          event: () => 'Location Create'
        }
      },
      {
        path: ':locationId/edit/:step?',
        name: 'location.edit',
        component: () => import('@views/location/create/index.vue').then((c) => c.default || c),
        meta: {
          title: 'Location Edit',
          event: () => 'Location Edit',
          requiresAuth: true,
          hideHeader: true,
          redirect: 'location.list'
        }
      },
      {
        path: ':locationId/share',
        name: 'location.share',
        component: () => import('@views/location/share/index.vue'),
        meta: {
          requiresAuth: true,
          hideHeader: true,
          redirect: 'location.list',
          event: () => 'Location Share'
        }
      },
      {
        path: ':locationId/share/:shareId',
        name: 'location.share.edit',
        component: () => import('@views/location/share/index.vue'),
        meta: {
          requiresAuth: true,
          hideHeader: true,
          redirect: 'location.list',
          event: () => 'Location Share edit',
          previewPage: 'location.share.preview'
        }
      },
      {
        path: ':locationId/share/preview/:shareId',
        name: 'location.share.preview',
        component: () => import('@views/location/share/preview/index.vue'),
        meta: {
          title: 'Location Share',
          requiresAuth: true,
          hideHeader: true,
          hideFooter: true,
          redirect: 'location.list',
          event: () => 'Location Share Preview',
          breadcrumb: (name) => name || 'Shared location',
          link: (route) =>
            `/${route.params.accountId}/${route.params.locationId}/share/preview/${route.params.shareId}`,
          hideHomeBreadcrumb: true
        },
        children: [
          {
            path: 'photos/:rank',
            name: 'location.share.photos.preview',
            component: () => import('@views/location/share/view/focusView.vue'),
            meta: {
              breadcrumb: (name) => name || 'Focus view',
              event: (route) => `${route.params.shareId}`,
              link: (route) => `/l/${route.params.shareId}/photos/${route.params.rank}`
            }
          }
        ]
      },
      {
        name: 'collection.layout',
        path: 'a',
        component: CollectionNavigationLayout,
        beforeEnter: routeHelper.beforeEnter,
        meta: {
          showBreadcrumb: false
        },

        children: [
          {
            path: 'collections',
            name: 'collection.list',
            component: () =>
              import('@views/collections/list/index.vue').then((c) => c.default || c),
            meta: {
              requiresAuth: true,
              title: 'Collections',
              event: () => 'Collections list',
              breadcrumb: (name) => name || 'Collection',
              link: (route) => `/${route?.params?.accountId}/a/collections`
            },
            children: [
              {
                path: ':collectionId',
                name: 'collection.view',
                component: () =>
                  import('@views/collections/list/index.vue').then((c) => c.default || c),
                meta: {
                  breadcrumb: (name) => name || 'Collection view',
                  event: () => 'Collections view',
                  link: (route) => `/collections/${route.params.collectionId}`
                },
              }
            ]
          }
        ]
      },
      
      {
        path: 'collection/manage/:collectionId',
        name: 'collection.manage',
        component: () => import('@views/collections/create/index.vue').then((c) => c.default || c),
        meta: {
          requiresAuth: true,
          showBreadcrumb: false,
          hideHeader: true,
          hideFooter: true,
          event: () => 'Collections create'
        }
      },
      {
        path: 'c/:collectionId/share/:shareId',
        name: 'collection.share.edit',
        component: () => import('@views/collections/share/index.vue'),
        meta: {
          requiresAuth: true,
          hideHeader: true,
          hideFooter: true,
          redirect: 'collection.list',
          event: () => 'Collection Share edit',
          previewPage: 'collection.share.preview'
        }
      },
      {
        path: 'c/:collectionId/share/preview/:shareId',
        name: 'collection.share.preview',
        component: () => import('@views/collections/share/preview/index.vue'),
        meta: {
          title: 'Collection Share',
          requiresAuth: true,
          hideHeader: true,
          hideFooter: true,
          redirect: 'collection.list',
          event: () => 'Collection Share Preview',
          breadcrumb: (name) => name || 'Shared collection',
          link: (route) =>
            `c/${route.params.accountId}/${route.params.collectionId}/share/preview/${route.params.shareId}`,
          hideHomeBreadcrumb: true
        }
      },
      {
        path: 'c/l/share/:shareId',
        name: 'collection.location.share.edit',
        component: () => import('@views/location/share/index.vue'),
        meta: {
          requiresAuth: true,
          hideHeader: true,
          redirect: 'location.list',
          event: () => 'Location Share edit',
          previewPage: 'collection.location.share.preview'
        }
      }
      // {
      //   path: 'c/l/share/preview/:shareId',
      //   name: 'collection.location.share.preview',
      //   component: () => import('@views/location/share/preview/index.vue'),
      //   meta: {
      //     title: 'Collection Location Share',
      //     requiresAuth: true,
      //     hideHeader: true,
      //     hideFooter: true,
      //     redirect: 'location.list',
      //     event: () => 'Location Share Preview',
      //     breadcrumb: (name) => name || 'Shared location',
      //     link: (route) =>
      //       `c/${route.params.accountId}/${route.params.collectionId}/share/preview/${route.params.shareId}`,
      //     hideHomeBreadcrumb: true
      //   },
      //   children: [
      //     {
      //       path: 'photos/:rank',
      //       name: 'location.share.photos.preview',
      //       component: () => import('@views/location/share/view/focusView.vue'),
      //       meta: {
      //         breadcrumb: (name) => name || 'Focus view',
      //         event: (route) => `${route.params.shareId}`,
      //         link: (route) => `/l/${route.params.shareId}/photos/${route.params.rank}`
      //       }
      //     }
      //   ]
      // }
    ]
  }
]
