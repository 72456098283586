import { CollectionService, LinkService } from '@services'
import cloneDeep from 'lodash/cloneDeep'
import concat from 'lodash/concat'
export default {
  data() {
    return {
      collectionList: [],
      params: {
        page: 1,
        limit: 16
      },
      totalPages: 0,
      totalCollection: 0,
      sharedData: null,
      actionKey: 0,
      actions: [
        {
          name: 'Share',
          value: 'share',
          icon: 'mdi-share',
          show: true
        },
        // {
        //   name: 'Set cover image',
        //   value: 'setCoverImage',
        //   icon: 'mdi-image-size-select-actual',
        //   show: true
        // },
        {
          name: 'Remove',
          value: 'delete',
          icon: 'mdi-delete',
          show: true
        },
        {
          name: 'Rename',
          value: 'rename',
          icon: 'mdi-form-textbox',
          show: true
        }
      ],
      breadCrumbPages: [],
      parent: null,
      currentCollection: null,
      intersectionObserver: null
    }
  },

  beforeRouteLeave(to, from, next) {
    this.destroyIntersectionObserver()
    next()
  },

  beforeDestroy() {
    if (this.intersectionObserver) {
      this.destroyIntersectionObserver()
    }
  },

  methods: {
    async getCollections(collectionSlug = null) {
      this.$loading.spinner = true
      if (collectionSlug) {
        this.params.parentId = collectionSlug
      }
      this.params.sortBy = 'isFile'
      const response = await CollectionService.getAll(this.params)
      if (response) {
        // Tempararily added for work
        this.collectionList =
          this.params.page > 1
            ? concat(this.collectionList, response.data?.data)
            : response.data?.data
        this.totalCollection = response.data.count
        if (response.data?.totalPages) this.totalPages = response.data?.totalPages
        if (response.data?.parent) this.parent = response.data?.parent
      }
      this.$loading.spinner = false
    },

    async getCollectionData(linkId, params = {}) {
      this.$loading.spinner = true
      let currentLinkId = this.currentCollection?.linkId ? this.currentCollection?.linkId : linkId
      this.params.sortBy = 'isFile'
      this.params = { ...this.params, ...params }
      const response = await LinkService.getAll(currentLinkId, this.params)
      if (response) {
        this.collectionList =
          this.params.page > 1
            ? concat(this.collectionList, response.data?.data)
            : response.data?.data
        this.totalCollection = response.data.count
        if (response.data?.totalPages) this.totalPages = response.data?.totalPages
        // if (response.data?.parent) this.currentOpenedCollecton = response.data?.parent
      }
      this.$loading.spinner = false
    },

    async getLinkData(linkId, updateData = true) {
      const response = await LinkService.get(linkId)

      if (response) {
        if (this.$route?.params?.shareId == response.data?.linkId) {
          this.sharedData = response.data
          if (!updateData) {
            this.setCurrentOpen(this.currentCollection)
          } else {
            this.setCurrentOpen(this.sharedData)
          }
        } else {
          this.currentCollection = response.data
          this.setCurrentOpen(this.currentCollection)
        }
        this.actionKey++
      }
    },

    async getCollectionPath(collection) {
      const isCollectionSetupPage = this.$route.name == 'collection.share.edit'
      const response = await LinkService.getPath(collection?.linkId)
      if (response) {
        this.breadCrumbPages = response?.data?.map((obj, index) => {
          if (isCollectionSetupPage) {
            let privateName = obj.meta?.collectionId?.name || obj.meta?.locationId?.name
            let titleText = privateName || obj?.title
            obj = { ...obj, title: titleText }
            if (obj.level === 0) {
              titleText = `${privateName} share setup`
              obj = { ...obj, title: titleText }
            }
          }
          return obj
        })
        const copyOfData = cloneDeep(collection)
        copyOfData.disabled = true
        if (this.breadCrumbPages?.length > 0 && isCollectionSetupPage) {
          copyOfData.title =
            copyOfData.meta?.collectionId?.name || copyOfData.meta?.locationId?.name
        }
        this.breadCrumbPages.push(copyOfData)
      }
    },

    registerObserver() {
      this.intersectionObserver = new IntersectionObserver(this.handleIntersect, {
        root: null,
        rootMargin: '0px 0px -10px 0px',
        threshold: 1
      })

      this.intersectionObserver.observe(this.$refs.endOfListIndicator)
    },

    destroyIntersectionObserver() {
      if (this.intersectionObserver) {
        this.intersectionObserver.disconnect()
        this.intersectionObserver = null
      }
    },

    async handleIntersect(entries) {
      const [entry] = entries
      if (entry.isIntersecting && this.totalCollection > this.collectionList?.length) {
        this.params.page++
        await this.getCollectionData(this.currentCollection?.linkId)
      }
    },

    getName(item) {
      let displayName = item?.title || item?.name
      if (this.$route?.name === 'collection.share.edit') {
        if (item?.meta?.locationId) {
          displayName = item?.meta?.locationId?.name
        }
        if (item?.meta?.collectionId) {
          displayName = item?.meta?.collectionId?.name
        }
      }
      return displayName
    }
  }
}
